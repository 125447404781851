import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatCamelCase"
})
export class FormatCamelCasePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (value) {
      const first = value.replace(/([A-Z])+/g, " $1").toLowerCase();
      const second = first.charAt(0).toUpperCase() + first.slice(1);
      return second;
    }
  }

}
