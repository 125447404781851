import { Directive, ElementRef, HostListener, Inject, Input } from "@angular/core";

@Directive({
  selector: "[appRouterLinkNewTab]"
})
export class RouterLinkNewTabDirective {
  @Input("routerLink") link: string;
  constructor(private el: ElementRef, @Inject(Window) private win: Window) {
  }
  @HostListener("mousedown") onMouseEnter() {
    this.win.open(this.link || "dashboard");
  }
}
