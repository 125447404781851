import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatDistance"
})
export class FormatDistancePipe implements PipeTransform {
  // tslint:disable:no-magic-numbers
  transform(value: any, args?: any): any {
    const distance = value / 1000;
    return distance.toFixed(2) + "km";
  }

}
