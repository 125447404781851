// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  apiUrl: "apiUrl",
  msDriverDataUrL: "/ms-driverdata",
  msUserManagementUrl: "/ms-usermanagement",
  msNotificationUrl: "/ms-notification",
  msMatchingUrl: "/ms-matching",
  msRideUrl: "/ms-ride",
  msRatingUrl: "/ms-rating",
  msPriceCalculationUrl: "/ms-price-calculation",
  msPromotions: "/ms-promotion",
  googleApiKey: "AIzaSyDBLV5ynZydQneAsqyijMIiX7ncMx32-0o"
};
