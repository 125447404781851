import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

moment.updateLocale("en", {
  week: {
    dow: 1, // First day of week is Monday
    doy: 4  // First week of year must contain 4 January (7 + 1 - 4)
  }
});

@Pipe({
  name: "formatTimeRange"
})
export class FormatTimeRangePipe implements PipeTransform {

  transform(value: { from: number; to: number }, args?: any): any {
    const from = moment(value.from).startOf("d");
    const to = moment(value.to).startOf("d");

    let date = "";
    if (from.month() === to.month()) {
      date = (from.day() === to.day()) ?
        to.format("DD MMMM | YYYY") :
        from.format("DD - ") + to.format("DD MMMM | YYYY");
    } else {
      date = (from.weekYear() === to.weekYear()) ?
        date = from.format("DD MMMM - ") + to.format("DD MMMM | YYYY") :
        date = from.format("DD MMMM YYYY - ") + to.format("DD MMMM YYYY");
    }
    return date;
  }

}
