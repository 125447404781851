import { Injectable } from "@angular/core";
import { BackendService } from "../backend";
export enum PayoutClientType {
  company = "company",
  driver = "driver"
}

@Injectable({
  providedIn: "root"
})
export class PayoutService {

  constructor(private backendService: BackendService) { }

  getPayoutPeriods(clientType: PayoutClientType) {
    return this.backendService.getBackend(`/${clientType}/payout/periods`);
  }

  getPayoutsForPeriod(clientType: PayoutClientType, query: string) {
    return this.backendService.getBackend(`/${clientType}/payout?${query}`);
  }

  calculatePayouts(clientType: PayoutClientType) {
    return this.backendService.postBackend(`/${clientType}/payout`, {});
  }

  generateXml(clientType: PayoutClientType, body: string) {
    return this.backendService.postBackend(`/${clientType}/payout/xml`, body);
  }

  confirmPayouts(clientType: PayoutClientType, body: string) {
    return this.backendService.putBackend(`/${clientType}/payout`, body);
  }

  setFailed(clientType: PayoutClientType, payoutId: string, description: string) {
    return this.backendService.putBackend(`/${clientType}/payout/${payoutId}/failed`, { description });
  }
}
