
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import * as HttpCodes from "http-status-codes";
import { throwError as observableThrowError } from "rxjs";
import { Observable } from "rxjs/Rx";
import { UserService } from "../data/user/user.service";

const jwtHelper = new JwtHelperService();

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public userService: UserService, private router: Router) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token: string = this.userService.getToken();
        const cityId = localStorage.getItem("currentCityId");

        if (token !== null) {
            const headers = {
                Authorization: `${token}`
            };
            if (cityId) {
                Object.assign(headers, { cityId });
            }
            request = request.clone({
                setHeaders: headers
            });
        }

        return next.handle(request)
            .catch((error: any, caught: any) => {
                console.log("Error in token interceptor", error);
                switch (error.status) {
                    case HttpCodes.UNAUTHORIZED:
                    case HttpCodes.FORBIDDEN:
                    case 0:
                        if (jwtHelper.isTokenExpired(this.userService.getToken())) {
                            console.log("Token is expired");
                            return this.userService.refreshToken()
                                .flatMap(t => {
                                    const authReq = request.clone({ headers: request.headers.set("Authorization", t) });
                                    return next.handle(authReq);
                                })
                                .catch((err: any, caught2: any): any => {
                                    // Cant refresh access token, redirect to login
                                    console.log("Error in refresh token");
                                    localStorage.clear();
                                    this.router.navigate(["login"]);
                                });
                        } else {
                            console.log("Token is not expired");
                            // this.router.navigate(["page-not-found"], { skipLocationChange: true });
                        }
                        break;
                    case HttpCodes.INTERNAL_SERVER_ERROR:
                        console.log("Internal server error: ", error.message);
                        localStorage.clear();
                        this.router.navigate(["login"]);
                        break;
                    case HttpCodes.NOT_ACCEPTABLE:
                        console.log("Invalid username or password");
                        break;
                    case HttpCodes.NOT_FOUND:
                        this.router.navigate(["page-not-found"], { skipLocationChange: true });
                        break;
                    default:
                        console.log("DEFAULT Error status: ", error.status);
                }
                return observableThrowError(error);
            }) as any;
    }
}
