import { Injectable } from "@angular/core";
import { BackendService } from "../backend";

@Injectable({
  providedIn: "root"
})
export class CityService {

  constructor(private backendService: BackendService) { }

  getCities() {
    return this.backendService.getBackend("/city");
  }

  getCountries() {
    return this.backendService.getBackend("/country");
  }

  getCurrencyByArea(areaId: string, areaRestriction: "CITY" | "COUNTRY") {
    return this.backendService.getBackend(`/currency/area/${areaId}?areaRestriction=${areaRestriction}`);
  }
}
