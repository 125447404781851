import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BackendService } from "../backend";
import { Promotion } from "./promotion";

@Injectable()
export class PromotionsService {

    constructor(private backendService: BackendService) {
    }

    getPromotions(includeExpiredPromotions: boolean): Observable<Promotion[]> {
        const query = includeExpiredPromotions ? `includeExpired=${includeExpiredPromotions}` : "";
        return this.backendService.getBackend(`/promotion?${query}`);
    }
    createPromotion(promotion: any): Observable<Promotion> {
        return this.backendService.postBackend("/promotion", promotion);
    }
    deletePromotion(promotionId: string) {
        return this.backendService.deleteBackend("/promotion/" + promotionId);
    }
    getPromotionDetails(promotionId: string): Observable<Promotion> {
        return this.backendService.getBackend("/promotion/" + promotionId);
    }
    getTotalPromoCodes(promotionId: string): Observable<Promotion> {
        return this.backendService.getBackend("/promotion/" + promotionId + "/total");
    }
}
