import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertComponent } from "ngx-bootstrap/alert/alert.component";
import { CarCategory } from "../data/car-categories/car-categories";
import { CarCategoriesService } from "../data/car-categories/car-categories.service";
import { Currency } from "../data/city/city";

@Component({
  selector: "app-car-categories",
  templateUrl: "./car-categories.component.html",
  styleUrls: ["./car-categories.component.scss"]
})
export class CarCategoriesComponent implements OnInit {

  alerts: any[] = [];
  errorMessage: string;
  carCategoriesForm: FormGroup;
  carCategoriesArray: FormArray;
  carCategories: CarCategory[];
  carCategoriesEditing: boolean[] = [];
  submitted: boolean = false;
  currency: Currency;

  constructor(
    private carCategoriesService: CarCategoriesService,
    private fb: FormBuilder,
  ) { }

  add(msg, type): void {
    this.alerts.push({
      type,
      msg,
      timeout: 2000
    });
  }

  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  ngOnInit() {
    this.initForm();
    this.getAllCarCategories();
  }

  initCarCategory() {
    return this.fb.group({
      id: [""],
      name: [0, Validators.required],
      initialPrice: [0, Validators.required],
      minPrice: [0, Validators.required],
      perKm: [0, Validators.required],
      perMin: [0, Validators.required],
      penaltyPrice: [0, Validators.required],
      active: [0, Validators.required]
    });
  }
  get formData() {
    return this.carCategoriesForm.get("carCategories") as FormArray;
  }

  initForm() {
    this.carCategoriesForm = this.fb.group({
      carCategories: this.fb.array([])
    });
  }

  cancelEditing(carCategoryIndex: number) {
    const carCategory = this.carCategories[carCategoryIndex];
    this.carCategoriesArray.controls[carCategoryIndex].patchValue({
      id: carCategory.id,
      name: carCategory.name,
      initialPrice: carCategory.initialPrice,
      minPrice: carCategory.minPrice,
      perKm: carCategory.perKm,
      perMin: carCategory.perMin,
      penaltyPrice: carCategory.penaltyPrice,
      active: carCategory.active
    });
  }

  async getAllCarCategories() {
    this.carCategoriesArray = this.carCategoriesForm.get("carCategories") as FormArray;
    this.carCategories = await this.carCategoriesService.getAllCarCategories().toPromise();

    for (const carCategory of this.carCategories) {
      this.carCategoriesArray.push(this.initCarCategory());
      this.carCategoriesEditing.push(false);
      // Set currency from any car category - they are from the same city
      this.currency = carCategory.currency;
    }
    this.fillCarCategories(this.carCategories);
  }

  fillCarCategories(carCategories) {
    carCategories.forEach((item, index) => {
      this.carCategoriesArray.controls[index].patchValue({
        id: item.id,
        name: item.name,
        initialPrice: item.initialPrice,
        minPrice: item.minPrice,
        perKm: item.perKm,
        perMin: item.perMin,
        penaltyPrice: item.penaltyPrice,
        active: item.active
      });
    });
  }

  updateCarCategories(id: number, form: any, index: number) {
    this.submitted = true;
    if (!form.valid) {
      return;
    }

    this.carCategoriesService.updateCarCategory(id, form.value)
      .subscribe(
        () => {
          this.submitted = false;
          this.add("Success", "info");
          this.carCategoriesEditing[index] = !this.carCategoriesEditing[index];
          // Reset values and reload page
          this.initForm();
          this.getAllCarCategories();
        },
        error => this.add(error, "danger")
      );
  }

  updateCarCategory(id, event) {
    const active = event.target.checked ? 1 : 0;
    this.carCategoriesService.updateCarCategory(id, { active })
      .subscribe();
  }

  validateNumberInput(event: any) {
    event.target.value = event.target.value.replace(/[^0-9\.]/g, "");
  }

}
