import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "../data/user/user.service";


@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"]
})
export class ProfileComponent implements OnInit {

  profileForm: FormGroup;
  passwordsMatch: boolean = true;
  formWarningText = "* Passwords do not match";
  name: string;
  role: string;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private location: Location) { }

  ngOnInit() {
    this.name = localStorage.getItem("name");
    this.role = localStorage.getItem("role");
    this.createForm();
  }

  createForm() {
    this.profileForm = this.fb.group({
      token: [localStorage.getItem("accessToken")],
      oldPassword: [null, Validators.required],
      newPassword: [null, [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[^ \t]{8,}$/)]],
      retypeNewPassword: [null, [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[^ \t]{8,}$/)]]
    });
  }

  changePassword() {
    const passwords = {
      oldPassword: this.profileForm.value.oldPassword,
      newPassword: this.profileForm.value.newPassword
    };

    this.userService.changePassword(passwords).subscribe(
      res => {
        this.location.back();
      },
      err => {
        this.formWarningText = "* " + err.message;
      }
    );
  }

  validateRetypeNewPassword(event) {
    const retypeNewPassword = this.profileForm.value.retypeNewPassword;
    const newPassword = this.profileForm.value.newPassword;
    this.passwordsMatch = newPassword !== retypeNewPassword ? false : true;
  }
}
