import { Pipe, PipeTransform } from "@angular/core";
// tslint:disable:no-magic-numbers
@Pipe({
  name: "ordinalNumber"
})
export class OrdinalNumberPipe implements PipeTransform {

  transform(value: number, args?: any): any {
    const lastDigit = value % 10;
    switch (value) {
      case 11:
      case 12:
        return `${value}th`;
      default:
    }
    switch (lastDigit) {
      case 1:
        return `${value}st`;
      case 2:
        return `${value}nd`;
      case 3:
        return `${value}rd`;
      default:
        return `${value}th`;
    }
  }

}
