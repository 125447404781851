import { Injectable } from "@angular/core";
import { BackendService } from "../backend";

@Injectable({
  providedIn: "root"
})
export class ClientsInsightsService {

  constructor(private backendService: BackendService) { }

  getTopDriversByRideStatus(query: string) {
    return this.backendService.getBackend(`/ride/stats/driver-ranking-by-status?${query}`);
  }
}
