import { Injectable } from "@angular/core";
import { BackendService } from "../backend";

@Injectable()
export class EmploymentTypesService {
    constructor(private backendService: BackendService) { }

    getEmploymentTypes() {
        return this.backendService.getBackend("/employment-type");
    }
}
