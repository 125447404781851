import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BackendService } from "../backend";
import { CarCategory } from "./car-categories";

@Injectable({
  providedIn: "root"
})
export class CarCategoriesService {

  constructor(private backendService: BackendService) {}

  getAllCarCategories(): Observable<CarCategory[]> {
    return this.backendService.getBackend("/car-category");
  }

  updateCarCategory(id: number, vehicle: Partial<CarCategory>): Observable<CarCategory> {
    return this.backendService.putBackend("/car-category/" + id, vehicle);
  }
}
