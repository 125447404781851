import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
  // tslint:disable:no-magic-numbers
  transform(value: any, args?: any): any {
    if(value < 0){
      // Invalid value
      return "No data";
    }
    if (value > 3600) {
      const hours = Math.floor(value / 60 / 60);
      const minutes = Math.floor(value / 60 % 60);
      return hours + "h " + this.minTwoDigits(minutes) + "min";
    } else {
      const minutes = Math.floor(value / 60);
      return minutes + "min";
    }
  }

  minTwoDigits(num) {
    return (num < 10 ? "0" : "") + num;
  }

}
