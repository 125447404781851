import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

moment.updateLocale("en", {
  week: {
      dow: 1, // First day of week is Monday
      doy: 4  // First week of year must contain 4 January (7 + 1 - 4)
  }
});

@Pipe({
  name: "formatWeekPeriod"
})
export class FormatWeekPeriodPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const startOfWeek = moment().weekYear(value.year).week(value.weekOfYear).startOf("w");
    const endOfWeek = moment().weekYear(value.year).week(value.weekOfYear).endOf("w");

    let payoutPeriod = "";
    if (startOfWeek.month() === endOfWeek.month()) {
      payoutPeriod = startOfWeek.format("DD - ") + endOfWeek.format("DD MMMM | YYYY");
    } else {
      payoutPeriod = (startOfWeek.weekYear() === endOfWeek.weekYear()) ?
        payoutPeriod = startOfWeek.format("DD MMMM - ") + endOfWeek.format("DD MMMM | YYYY") :
        payoutPeriod = startOfWeek.format("DD MMMM YYYY - ") + endOfWeek.format("DD MMMM YYYY");
    }
    return payoutPeriod;
  }

}
