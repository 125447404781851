import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { City } from "../data/city/city";
import { CityService } from "../data/city/city.service";
import { User } from "../data/user/user";
import { UserService } from "../data/user/user.service";
declare var $: any;

@Component({
  selector: "app-people",
  templateUrl: "./people.component.html",
  styleUrls: ["./people.component.scss"]
})
export class PeopleComponent implements OnInit {
  roles = ["admin", "superAdmin", "marketingAdmin"];
  addUserForm: FormGroup;
  role;
  searchText: string = "";
  users: User[];
  errorMessage: string = "";
  isOpened: boolean = false;
  adminEmailDelete = "";
  spinner = false;
  selectedSortBy = "Sort by...";
  sortByOptions = [
    "name",
    "email",
    "role",
    "status"
  ];
  cities: City[];
  mappedCities = {};
  displayCitiesDropdown = true;

  constructor(private userService: UserService, private cityService: CityService, private fb: FormBuilder) { }

  async ngOnInit() {
    this.role = localStorage.getItem("role");
    this.createForm();
    await Promise.all([
      this.getUsers(),
      this.getCities()
    ]);
  }

  add() {
    if (this.addUserForm.valid) {
      this.userService.addUser(this.addUserForm.value).subscribe(
        res => {
          this.createForm();
          $("#addPeopleModal").modal("hide");
          this.getUsers();
        },
        err => {
          this.errorMessage = err.message;
        }
      );
    }
  }

  getUsers() {
    this.spinner = true;
    this.userService.getUsers().subscribe(
      res => {
        this.users = res;
        this.spinner = false;
      },
      err => {
        this.errorMessage = err.message;
      }
    );
  }

  createForm() {
    this.addUserForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      role: [this.roles[0], [Validators.required]],
      cityId: [null, [Validators.required]]
    });
  }

  deleteAdmin() {
    this.userService.deleteAdmin(this.adminEmailDelete).subscribe(() => {
      $("#deleteAdminModal").modal("hide");
      this.getUsers();
    });
  }

  openDeleteAdminModal(email) {
    this.isOpened = !this.isOpened;
    this.adminEmailDelete = email;
  }

  async getCities() {
    this.cities = await this.cityService.getCities().toPromise();
    this.cities.forEach(city => {
      this.mappedCities[city.id] = {
        ...city
      };
    });
    this.addUserForm.patchValue({ cityId: this.cities[0].id });
  }

  onRoleChanged(value: string) {
    switch (value) {
      case "admin":
        this.displayCitiesDropdown = true;
        this.addUserForm.patchValue({ cityId: this.cities[0].id });
        break;
      case "superAdmin":
      case "marketingAdmin":
        this.displayCitiesDropdown = false;
        this.addUserForm.patchValue({ cityId: "all" });
        break;
    }
  }

  ngOnDestroy() {
    $(".modal .close").click();
  }
}
