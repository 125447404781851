import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BackendService } from "../backend";
import { User } from "./user";

@Injectable()
export class UserService {
    constructor(private backendService: BackendService) { }

    loginUser(data) {
        return this.backendService.postBackend("/admin/login", data);
    }
    forgotPassword(data) {
        return this.backendService.getBackend(`/admin/password/forgot?username=${data.username}`);
    }
    forgotPasswordConfirmation(username, data) {
        return this.backendService.putBackend(`/admin/password/confirm?username=${username}`, data);
    }
    changePassword(data) {
        return this.backendService.putBackend("/admin/password/change", data);
    }
    addUser(data) {
        return this.backendService.postBackend(`/admin/register/${data.role}`, { email: data.email, cityId: data.cityId });
    }
    getUsers(query?: string): Observable<User[]> {
        return this.backendService.getBackend("/admin/users")
            .map(response => response);
    }
    getToken(): string {
        return localStorage.getItem("accessToken");
    }
    setTokens(accessToken: string) {
        // localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem("accessToken", accessToken);
    }
    refreshToken() {
        const refreshToken = localStorage.getItem("refreshToken");
        return this.backendService.getBackend("/admin/refresh-token", refreshToken)
            .map(
                refreshResponse => {
                    const newAccessToken: string = refreshResponse.accessToken;
                    // const newRefreshToken: string = refreshResponse.refreshToken;
                    this.setTokens(newAccessToken);
                    return newAccessToken;
                });
    }

    deleteAdmin(username) {
        return this.backendService.deleteBackend(`/admin/${username}`);
    }
}
