import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BackendService } from "../backend/backend.service";
import { Ride } from "./ride";

@Injectable()
export class RideService {
  ridesQuery = null;

  constructor(private backendService: BackendService) {
  }
  getRides(query?): Observable<Ride[]> {
    if (!query) { query = ""; }
    return this.backendService.getBackend("/ride" + query);
  }
  getRideById(id: string): Observable<Ride> {
    return this.backendService.getBackend("/ride/" + id)
      .map(response => {
        return response;
      });
  }

  getRatings(rideId: string): Observable<any> {
    return this.backendService.getBackend("/ride/rating?rideId=" + rideId);
  }

  getCreditCards(id: string): Observable<any> {
    return this.backendService.getBackend("/passenger/" + id + "/credit-card")
      .map(response => response);
  }
  adjustPrice(adjustmentData: any) {
    return this.backendService.postBackend("/ride/adjustment", adjustmentData);
  }
  adjustDriverEarnings(adjustmentData: any) {
    return this.backendService.postBackend("/ride/driver-earnings", adjustmentData);
  }
  voidIncompletePayment(voidPayment: { rideId: string, description: string }) {
    return this.backendService.postBackend("/ride/void", voidPayment);
  }

  getRidePaymentDetails(rideId: string): Observable<any> {
    return this.backendService.getBackend("/ride/" + rideId + "/payments");
  }

  getRidePromoCode(rideId: string): Observable<any> {
    return this.backendService.getBackend("/ride/" + rideId + "/promo-code");
  }

  forceDropOff(rideId: string) {
    return this.backendService.putBackend("/ride/force-drop-off", { rideId });
  }

  getMatchingHistory(rideId: string) {
    return this.backendService.getBackend("/matching/" + rideId);
  }

  getTotalNumberOfRides(query: string) {
    if (!query) {
      query = "";
    }
    return this.backendService.getBackend("/ride/stats/total" + query);
  }

  changeRating(ratingId: string, rating: number){
    return this.backendService.putBackend(`/ride/rating/${ratingId}`, { rating });
  }
}
