import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "filterOnlineClients"
})
export class FilterOnlineClients implements PipeTransform {
    transform(items: any[], selectedFilter: string): any[] {
        if (!items) {
            return [];
        }
        if (selectedFilter === "All types") {
            return items;
        }
        if (selectedFilter !== "All types") {
            return items.filter(it => {
                return it.type === selectedFilter.toLowerCase();
            });
        }

    }
}
