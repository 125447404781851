import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Currency, SymbolPosition } from "../../data/city/city";

@Pipe({
  name: "formatCurrency"
})
export class FormatCurrencyPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(value: any, currency: Currency): any {
    if (!currency) {
      return value;
    }
    const currencyData = `<span style='font-size: ${currency.symbolSize * 100}%'>${currency.symbol}</span>`;
    const priceData = value || value === 0 ? `${value.toFixed(2)}` : value;
    const result = (currency.symbolPosition === SymbolPosition.PREFIX) ?
      `${currencyData}${priceData}` :
      `${priceData}${currencyData}`;
    return this.sanitizer.bypassSecurityTrustHtml(result);
  }

}
