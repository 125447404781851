import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    if (localStorage.getItem("accessToken")) {
      // logged in so return true
      return true;
    }

    this.router.navigate(["login"]);
    return false;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    if (localStorage.getItem("accessToken") != null) {
      const role = localStorage.getItem("role");
      if (role) {
        if (route.data.access === role || route.data.access === "All") {
          return true;
        }
        this.router.navigate(["/forbidden"]);
        return false;
      }
      this.router.navigate(["login"]);
      return false;
    }
    this.router.navigate(["login"]);
    return false;
  }
}
