import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BackendService } from "../backend";
import { Referral, ReferralCampaign, ReferralCode } from "./referrals";

@Injectable()
export class ReferralsService {

    constructor(private backendService: BackendService) {
    }

    getReferralCampaings(includeExpired: boolean): Observable<ReferralCampaign[]> {
        const query = includeExpired ? `includeExpired=${includeExpired}` : "";
        return this.backendService.getBackend(`/referral-campaign?${query}`);
    }

    getReferralCampaign(referralCampaignId: string): Observable<ReferralCampaign> {
        return this.backendService.getBackend(`/referral-campaign/${referralCampaignId}`);
    }

    getReferralCodes(referralCampaignId: string): Observable<ReferralCode[]> {
        return this.backendService.getBackend(`/referral-campaign/${referralCampaignId}/referral-code`);
    }

    getReferralsForReferralCode(referralCampaignId: string, referralCodeId: string): Observable<Referral[]> {
        return this.backendService.getBackend(`/referral-campaign/${referralCampaignId}/referral-code/${referralCodeId}/referrals`);
    }
}
