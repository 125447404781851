import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { HttpModule } from "@angular/http";

import { BackendService } from "./backend/backend.service";
import { RideService } from "./ride/ride.service";

// Services && Stores


@NgModule({
    imports: [
        HttpModule
    ],
    declarations: [
    ],
    providers: [
        BackendService,
        RideService
    ]
})
export class DataModule { }
