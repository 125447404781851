import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AppRootComponent } from "./app.root.component";
import { AuthGuard } from "./auth/auth.guard";
import { CarCategoriesComponent } from "./car-categories/car-categories.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ClientsInsightsComponent } from "./clients-insights/clients-insights.component";
import { DriverPayoutsComponent } from "./driver-payouts/driver-payouts.component";
import { CompanyPayoutsComponent } from "./company-payouts/company-payouts.component";
import { ForbiddenComponent } from "./forbidden/forbidden.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { LoginComponent } from "./login/login.component";
import { OnlineClientsComponent } from "./online-clients/online-clients.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { PassengersComponent } from "./passengers/passengers.component";
import { PeopleComponent } from "./people/people.component";
import { ProfileComponent } from "./profile/profile.component";

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: "login",
        component: LoginComponent
      },
      {
        path: "forgot-password",
        component: ForgotPasswordComponent
      },
      {
        path: "",
        component: AppRootComponent,
        children: [
          {
            path: "",
            redirectTo: "rides",
            pathMatch: "full"
          },
          {
            path: "dynamic",
            loadChildren: "./dynamic-prices/dynamic-prices.module#DynamicPricesModule",
            canActivate: [AuthGuard],
            data: { access: "superAdmin" }
          },
          {
            path: "people",
            component: PeopleComponent,
            canActivate: [AuthGuard],
            data: { access: "superAdmin" }
          },
          {
            path: "passengers",
            component: PassengersComponent,
            canActivate: [AuthGuard],
            data: { access: "All" }
          },
          {
            path: "driver-payouts",
            component: DriverPayoutsComponent,
            canActivate: [AuthGuard],
            data: { access: "superAdmin" }
          },
          {
            path: "company-payouts",
            component: CompanyPayoutsComponent,
            canActivate: [AuthGuard],
            data: { access: "superAdmin" }
          },
          {
            path: "drivers",
            loadChildren: "./drivers/drivers.module#DriversModule",
            canActivate: [AuthGuard],
            data: { access: "All" }
          },
          {
            path: "companies",
            loadChildren: "./companies/companies.module#CompaniesModule",
            canActivate: [AuthGuard],
            data: { access: "All" }
          },
          {
            path: "rides",
            loadChildren: "./rides/rides.module#RidesModule",
            canActivate: [AuthGuard],
            data: { access: "All" }
          },
          {
            path: "car-categories",
            component: CarCategoriesComponent,
            canActivate: [AuthGuard],
            data: { access: "superAdmin" }
          },
          {
            path: "driver-matching-factors",
            loadChildren: "./driver-matching-factors/driver-matching-factors.module#DriverMatchingFactorsModule",
            canActivate: [AuthGuard],
            data: { access: "superAdmin" }
          },

          {
            path: "predefined-places",
            loadChildren: "./predefined-places/predefined-places.module#PredefinedPlacesModule",
            canActivate: [AuthGuard],
            data: { access: "superAdmin" }
          },
          {
            path: "profile",
            component: ProfileComponent,
            canActivate: [AuthGuard],
            data: { access: "All" }
          },
          {
            path: "promotions",
            loadChildren: "./promotions/promotions.module#PromotionsModule",
            canActivate: [AuthGuard],
            data: { access: "All" },
          },
          {
            path: "referral-campaigns",
            loadChildren: "./referrals/referral-campaigns.module#ReferralsModule",
            canActivate: [AuthGuard],
            data: { access: "All" },
          },
          {
            path: "forbidden",
            component: ForbiddenComponent,
            canActivate: [AuthGuard],
            data: { access: "All" }
          },
          {
            path: "online-clients",
            component: OnlineClientsComponent,
            canActivate: [AuthGuard],
            data: { access: "All" }
          },
          {
            path: "dashboard",
            component: DashboardComponent,
            canActivate: [AuthGuard],
            data: { access: "superAdmin" }
          },
          {
            path: "clients-insights",
            component: ClientsInsightsComponent,
            canActivate: [AuthGuard],
            data: { access: "superAdmin" }
          },
          {
            path: "collaborator-types",
            loadChildren: "./collaborator-types/collaborator-types.module#CollaboratorTypesModule",
            canActivate: [AuthGuard],
            data: { access: "superAdmin" }
          },
          {
            path: "page-not-found",
            component: PageNotFoundComponent
          },
          {
            path: "**",
            component: PageNotFoundComponent,
            canActivate: [AuthGuard],
            data: { access: "All" }
          },
        ]
      }
    ])
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
