import { Component, OnInit } from "@angular/core";
import { ClientsInsightsService } from "app/data/clients-insights/clients-insights.service";
import { EmploymentType } from "app/data/driver/driver";
import { EmploymentTypesService } from "app/data/employment-type/employment-types.service";
import * as moment from "moment";

interface TimeRange {
  from: number;
  to: number;
}

@Component({
  selector: "app-clients-insights",
  templateUrl: "./clients-insights.component.html",
  styleUrls: ["./clients-insights.component.scss"],
  providers: [ EmploymentTypesService, ClientsInsightsService ]
})
export class ClientsInsightsComponent implements OnInit {
  topDriversByStatus: any[];
  statisticsPeriod: Date[] = [new Date(moment().startOf("W").valueOf()), new Date(moment().endOf("W").valueOf())];
  period: TimeRange = {
    from: moment(this.statisticsPeriod[0]).startOf("d").valueOf(),
    to: moment(this.statisticsPeriod[1]).endOf("d").valueOf()
  };
  filter: { limit: number, status: string, employmentTypeId: string, order: "ASC" | "DESC" } = {
    limit: 10,
    status: "finishedRide",
    employmentTypeId: undefined,
    order: "DESC"
  };        // Initial rendering safeguard
  selectedRideStatus;
  selectedEmploymentTypeId;
  employmentTypes: EmploymentType[];
  rideStatuses = [
    "finishedRide",
    "canceledByDriver",
    "canceledByPassenger",
    "passengerNoShow"
  ];
  limits = [ 10, 20, 50];

  constructor(
    private clientsInsightsService: ClientsInsightsService,
    private employmentTypeService: EmploymentTypesService) { }

  async ngOnInit() {
    await this.getEmploymentTypes();
    this.filter = {
      limit: this.limits[0],
      status: this.rideStatuses[0],
      employmentTypeId: this.employmentTypes[0].id,
      order: "DESC"
    };
    this.getStatistics();
  }

  async getDriverRankingByStatus(period: TimeRange) {
    const query = `from=${period.from}&to=${period.to}&limit=${this.filter.limit}&status=${this.filter.status}&employmentTypeId=${this.filter.employmentTypeId}&order=${this.filter.order}`;
    this.topDriversByStatus = await this.clientsInsightsService.getTopDriversByRideStatus(query).toPromise().catch(e => []);
  }

  async getStatistics(){
    this.period = {
      from: moment(this.statisticsPeriod[0]).startOf("d").valueOf(),
      to: moment(this.statisticsPeriod[1]).endOf("d").valueOf()
    };
    Promise.all([
      this.getDriverRankingByStatus(this.period)
    ]);
  }

  async getEmploymentTypes() {
    this.employmentTypes = await this.employmentTypeService.getEmploymentTypes().toPromise();
  }

  changeSearch() {
    this.getStatistics();
  }

}
