import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "driversFilter"
})
export class DriversFilterPipe implements PipeTransform {
  transform(drivers: any[], searchText: string, selectedStatus?: string): any[] {
    if (!drivers) {
      return [];
    }
    if (searchText) {
      // filter by name
      return drivers.filter(driver => {
        return this.filterByName(driver, searchText);
      });
    } else {
      // return original array
      return drivers;
    }
  }

  filterByName(driver: any, searchText: string) {
    const fullName = `${driver.firstName} ${driver.lastName}`;
    const fullNameReversed = `${driver.lastName} ${driver.firstName}`;
    return fullName.toLowerCase().includes(searchText.toLowerCase()) || fullNameReversed.toLowerCase().includes(searchText.toLowerCase());
  }

  // filterByStatus(driver: any, selectedStatus: string) {
  //   switch (selectedStatus) {
  //     case "pending":
  //       return driver.statuses.globalStatus === "pending" || (driver.statuses.vehiclesStatus === "pending" && driver.statuses.globalStatus === "approved");
  //     default:
  //       return driver.statuses.globalStatus === selectedStatus.toLowerCase();
  //   }
  // }

}
