import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: "formatPace"
})
export class FormatPacePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(value: any, args?: any): any {
    let times: number;
    let speed: string;
    let color: string;

    if (value === 0) {
      return "No info";
    } else if (value < 0) {
      return this.sanitizer.bypassSecurityTrustHtml("<span style='color: red'>Too slow</span>");
    } else if (value > 1) {
      // const percentage = (value - 1) * 100; return `${percentage.toFixed()}% faster`;
      times = value;
      speed = "faster";
      color = "#28a745";
    } else {
      // const percentage = ((1 / value) - 1) * 100; return `${percentage.toFixed()}% slower`;
      times = this.roundNumber(1 / value);
      speed = "slower";
      color = value >= 0.5 ? "#ffbf00" : "red";
    }

    const html = `<span style='color: ${color}'>${times}x ${speed}</span>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  roundNumber(value: number): number {
    return Math.round(Number(value) * 100) / 100;
  }

}
