import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
// Tabs Component
import { TabsModule } from "ngx-bootstrap/tabs";
import { PipesModule } from "./pipes/pipes.module";
import { RouterLinkNewTabDirective } from "./router-link-new-tab.directive";

@NgModule({
    imports: [
        BsDropdownModule,
        // ComponentsModule,
        CommonModule,
        PipesModule,
        TabsModule
    ],
    declarations: [
        RouterLinkNewTabDirective
    ],
    exports: [
        RouterLinkNewTabDirective
    ]
})
export class SharedModule { }
