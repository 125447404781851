import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BackendService } from "../backend";

@Injectable()
export class OnlineClientsService {

  constructor(private backendService: BackendService) { }
  getOnlineClients(): Observable<any> {
    return this.backendService.getBackend("/socket/clients")
      .map(response => response);
  }
  getPassengerDetails(id: string): Observable<any> {
    return this.backendService.getBackend("/passenger/" + id + "/profile")
      .map(response => response);
  }
  getDriverDetails(id: string): Observable<any> {
    return this.backendService.getBackend("/driver/" + id + "/profile")
      .map(response => response);
  }

}
