import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AlertModule, BsDatepickerModule, TimepickerModule } from "ngx-bootstrap";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";
import { LightboxModule } from "ngx-lightbox";
import { AppComponent } from "./app.component";
import { AppRootComponent } from "./app.root.component";
import { AppRoutingModule } from "./app.routing.module";
import { AuthGuard } from "./auth/auth.guard";
import { TokenInterceptor } from "./auth/token.interceptor";
import { CarCategoriesComponent } from "./car-categories/car-categories.component";
import { ClientsInsightsComponent } from "./clients-insights/clients-insights.component";
import { CompanyPayoutsComponent } from "./company-payouts/company-payouts.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DataModule } from "./data/data.module";
import { OnlineClientsService } from "./data/online-clients/online-clients.service";
import { PassengerService } from "./data/passenger/passenger.service";
import { PromotionsService } from "./data/promotions/promotions.service";
import { ReferralsService } from "./data/referrals/referrals.service";
import { UserService } from "./data/user/user.service";
import { DriverPayoutsComponent } from "./driver-payouts/driver-payouts.component";
import { EnvServiceProvider } from "./env.service.provider";
import { ForbiddenComponent } from "./forbidden/forbidden.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { LoginComponent } from "./login/login.component";
import { OnlineClientsComponent } from "./online-clients/online-clients.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { PassengersComponent } from "./passengers/passengers.component";
import { PeopleComponent } from "./people/people.component";
import { ProfileComponent } from "./profile/profile.component";
import { AsideToggleDirective } from "./shared/aside.directive";
import { NAV_DROPDOWN_DIRECTIVES } from "./shared/nav-dropdown.directive";
import { PipesModule } from "./shared/pipes/pipes.module";
import { SharedModule } from "./shared/shared.module";
import { SIDEBAR_TOGGLE_DIRECTIVES } from "./shared/sidebar.directive";

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    DataModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule.forRoot(),
    PipesModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BrowserAnimationsModule,
    LightboxModule,
    SharedModule
  ],
  declarations: [
    AppComponent,
    AppRootComponent,
    NAV_DROPDOWN_DIRECTIVES,
    SIDEBAR_TOGGLE_DIRECTIVES,
    AsideToggleDirective,
    LoginComponent,
    PeopleComponent,
    ProfileComponent,
    ForgotPasswordComponent,
    ForbiddenComponent,
    OnlineClientsComponent,
    PageNotFoundComponent,
    PassengersComponent,
    DriverPayoutsComponent,
    DashboardComponent,
    CarCategoriesComponent,
    ClientsInsightsComponent,
    CompanyPayoutsComponent
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    UserService,
    PromotionsService,
    ReferralsService,
    OnlineClientsService,
    AuthGuard,
    EnvServiceProvider,
    PassengerService,
    { provide: Window, useValue: window }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
