import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: "simpleTextFilter"
})
export class SimpleTextFilterPipe implements PipeTransform {

  transform(items: any[], attr: string, searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (searchText) {
      return items.filter(item => {
        return item[attr].toLowerCase().includes(searchText.toLowerCase());
      });
    } else {
      return items;
    }
  }

}
