import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "../data/user/user.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent implements OnInit {

  emailForm: FormGroup;
  changePasswordForm: FormGroup;
  step: number;
  submittedEmail: boolean;
  submittedFinal: boolean;
  errorMessage: string = "";

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private location: Location) { }

  ngOnInit() {
    this.step = 1;
    this.createForm();
  }

  createForm() {
    this.emailForm = this.fb.group({
      username: ["", [Validators.required, Validators.email]]
    });
    this.changePasswordForm = this.fb.group({
      newPassword: [null, [Validators.required, Validators.minLength(8)]],
      activationCode: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });
  }

  changeStep() {
    this.submittedEmail = true;
    if (this.emailForm.valid) {
      this.forgotPassword(this.emailForm.value);
    }
  }

  setNewPassword() {
    this.submittedFinal = true;
    if (!this.changePasswordForm.valid) {
      return;
    }
    this.forgotPasswordConfirmation();
  }

  forgotPassword(username: string) {
    this.userService.forgotPassword(username).subscribe(
      res => {
        console.log("res in forgot password", res);
        this.step = 2;
        this.errorMessage = "";
      },
      err => {
        this.errorMessage = err.message;
      }
    );
  }
  forgotPasswordConfirmation() {
    const data = {
      password: this.changePasswordForm.value.newPassword,
      confirmationCode: this.changePasswordForm.value.activationCode
    };

    this.userService.forgotPasswordConfirmation(this.emailForm.value.username, data).subscribe(
      res => {
        this.location.back();
      },
      err => {
        this.errorMessage = err.message;
      });
  }

}
