import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "app/app.module";
import { environment } from "environments/environment";

// function loadScript() {
//   const src = "https://maps.googleapis.com/maps/api/js?&key=" + environment.googleApiKey + "&libraries=drawing";
//   let script = document.createElement('script');
//   script.type = 'text/javascript';
//   script.src = src;
//   document.head.appendChild(script);
// }

// loadScript()

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);

