import { Component, OnInit } from "@angular/core";
import { OnlineClientsService } from "../data/online-clients/online-clients.service";

// tslint:disable:no-magic-numbers

declare var google: any;
declare var $: any;

@Component({
  selector: "app-online-clients",
  templateUrl: "./online-clients.component.html",
  styleUrls: ["./online-clients.component.scss"]
})
export class OnlineClientsComponent implements OnInit {
  map: any;
  marker: any;
  markersArray = [];
  onlineClients: any[] = [];
  selectedFilter = "All types";
  displayType = "list";
  interval: any;
  clientDetails: any = {};
  carTypes = [
    { id: 0, name: "Vertt" },
    { id: 1, name: "Premium" },
    { id: 2, name: "Lux" },
  ];
  spinner = false;

  constructor(
    private onlineClientsService: OnlineClientsService,
  ) { }

  async ngOnInit() {
    // this.getOnlineClients();
    // this.initMap(this.filterDrivers(this.onlineClients));
    // this.autoReloadOnlineClients();
  }

  autoReloadOnlineClients() {
    this.interval = setInterval(() => {
      this.getOnlineClients();
      this.fillMap(this.filterDrivers(this.onlineClients));
    }, 8000);
  }

  async reloadOnlineClients() {
    this.getOnlineClients();
    this.fillMap(this.filterDrivers(this.onlineClients));
  }

  getOnlineClients() {
    this.spinner = true;
    this.onlineClientsService.getOnlineClients().subscribe(clients => {
      console.log("Online clients: ", clients);
      this.onlineClients = clients;
      this.spinner = false;
    });
  }

  filterDrivers(clients) {
    return clients.filter(client => client.type === "driver");
  }

  changeDisplayType(displayType) {
    this.displayType = displayType;
    this.reloadOnlineClients();
  }

  getClientDetails(client: any) {
    if (client.type === "passenger") {
      this.onlineClientsService.getPassengerDetails(client.clientId).subscribe(res => {
        this.clientDetails = res;
        Object.assign(this.clientDetails, {
          clientType: client.type,
          carTypes: client.carTypes,
          paymentMethods: client.paymentMethods
        });
      });
    } else {
      this.onlineClientsService.getDriverDetails(client.clientId).subscribe(res => {
        this.clientDetails = res;
        Object.assign(this.clientDetails, {
          clientType: client.type,
          carTypes: client.carTypes,
          paymentMethods: client.paymentMethods
        });
      });
    }
  }

  initMap(clients) {
    this.map = new google.maps.Map(document.getElementById("map"), {
      // center: { lat: 41.995493, lng: 21.42239 },   // Skopje
      center: { lat: 47.3863168, lng: 8.4867095 },    // Zurich
      zoom: 15
    });
    this.fillMap(clients);
  }

  clearMarkers() {
    // Clear markers on map
    for (const marker of this.markersArray) {
      marker.setMap(null);
    }
    // Empty markers array
    this.markersArray = [];
  }

  fillMap(clients) {
    this.clearMarkers();

    for (const client of clients) {
      if (client.location) {
        // let angle = -Number(client.direction);
        // let x = Math.sin(-angle * Math.PI / 180) * 0.5 + 0.5;
        // let y = -(Math.cos(-angle * Math.PI / 180) * 0.5 - 0.5);
        const carImage = {
          path: "M422 1118 c-95 -46 -119 -121 -107 -337 4 -81 9 -268 11 -418 1 -175 6 -278 13 -292 25 -46 65 -56 231 -56 166 0 206 10 231 56 7 13 12 119 13 297 2 153 7 340 11 418 12 212 -13 287 -109 333 -65 30 -231 30 -294 -1z m296 -273 c38 -12 52 -21 52 -35 0 -10 5 -20 10 -22 17 -5 0 -308 -17 -308 -10 0 -13 19 -13 74 0 87 11 81 -165 97 -86 7 -129 7 -149 -1 -16 -6 -31 -8 -33 -6 -6 6 -33 144 -33 167 0 15 39 31 125 53 36 9 169 -2 223 -19z m -285 -604 c7 -29 77 -44 177 -38 47 3 94 7 105 9 17 3 20 -3 23 -43 3 -45 3 -46 -38 -58 -58 -17 -220 -15 -265 3 -38 16 -39 22 -29 100 7 48 18 60 27 27z",
          rotation: Number(client.direction) - 180 || 180,
          scale: 0.03,
          fillColor: "#b300b3",
          fillOpacity: 1,
          strokeColor: "black",
          strokeWeight: 1,
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(610, 610)
        };

        this.marker = new google.maps.Marker({
          position: { lat: Number(client.location.lat), lng: Number(client.location.lng) },
          map: this.map,
          icon: carImage
        });

        this.markersArray.push(this.marker);

        this.marker.addListener("click", () => {
          this.getClientDetails(client);
          $("#clientDetails").modal("show");
        });
        this.marker.setMap(this.map);
      }
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    $(".modal .close").click();
  }

}
